// Generic imports
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

// Other components imports
import Layout from "components/layout"
import SEO from "components/seo"
import CreateClass from "components/CreateClass"

// Utils imports
import { isBrowser } from "services/utils"
import { getOneClass } from "services/kelas"

const EditKelasTemplate = () => {
  const [kelas, setKelas] = useState({})

  useEffect(() => {
    try {
      if (isBrowser()) {
        const pathList = window.location.pathname.split("/")
        const kelasCode = pathList[2]
        getOneClass(kelasCode)
          .then(resp => {
            if (resp.data) {
              setKelas(resp.data[0])
            } else {
              throw new Error()
            }
          })
          .catch(() => {
            navigate("/")
          })
      }
    } catch {
      navigate("/")
    }
  }, [])

  return (
    <Layout>
      <SEO title={kelas ? `Edit Kelas: ${kelas.name}` : "Edit Kelas"} />
      <div>{!kelas ? "Loading..." : <CreateClass kelas={kelas} isEdit />}</div>
    </Layout>
  )
}

export default EditKelasTemplate
